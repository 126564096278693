@import "@ashishdotme/ui/styles/_theme.sass";

.hero {
  background: #8e2de2;
  background: -webkit-linear-gradient(
    to right,
    #8e2de2,
    #4a00e0
  );
  background: linear-gradient(to right, #8e2de2, #4a00e0);
}

.card {
  border-radius: 5px
}

@media screen and (min-width: 1472px) {
  .container {
    max-width: 1344px;
    width: 1344px;
  }
}
